import React from 'react'
import Papers from '../components/Papers'
import page from '~utils/page'
import { RESOURCES } from '~shared/Nav'
import Head from '~shared/Head'

// eslint-disable-next-line import/no-unused-modules
export default page(Papers, {
    highlight: RESOURCES,
    headComponent: (
        <Head
            mediaDescription="Download technical and research papers"
            description="Whitepapers, Decentralised technology, real-time data, pub-sub network, peer-to-peer network"
        />
    ),
})
