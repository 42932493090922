import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from '~shared/FluidImage'

export default function LimeChainAudit(props) {
    const data = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "Papers/assets/LimeChainAudit.jpg" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
                }
            }
        }
    `).file.childImageSharp.gatsbyImageData

    return <Img {...props} data={data} />
}
